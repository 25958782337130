

import React, { useState, useReducer } from "react";
import { Button, IconButton, Divider} from "@mui/material";
import { Add, Search, Remove, Navigation, Layers, Polyline, Delete, AddCircleOutline, CloudUpload, TextSnippetOutlined } from '@mui/icons-material';

import { MapLibre, MapOverlay, StartupOptions } from "../components/map";
import { showToast, ToastKind, ToastAutoClose } from '../components/ui';
import { ConfirmationDialog, CreatePlotDialog, MaintainHedgerowDialog } from '../components/dialogs';
import { getPostcode } from '../apiClients/postcodes/api';
import { Hedgerow, Plot } from '../models/plot';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import styles from './plotMapPage.module.css';
import globalStyles from '../styles/global.module.css';
import logo from '../assets/logo-green-transparent.png';
import Map from '../assets/map.svg';

import { HedgerowList } from "../components/core/hedgerowList";
import { DateTime } from "luxon";
import { calculateAreaM2 } from '../helpers/mapHelper'
import { createNewHedgerow, isPlotSubmitted, isPlotValid } from '../helpers/plotHelper';
import { SubmitPlotDialog } from "../components/dialogs/submitPlotDialog";

const VisibleDialog = {
  None: 'None',
  CreatePlot: 'CreatePlot',
  CreateHedgerow: 'CreateHedgeRow',
  EditHedgerow: 'EditHedgeRow',
  DeleteHedgerow: 'DeleteHedgerow',
  DeleteHedgerowArea: 'DeleteHedgerowArea',
  SubmitPlot: 'SubmitPlot',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type VisibleDialog = typeof VisibleDialog[keyof typeof VisibleDialog];

export const PlotMapPage = () : JSX.Element => {
  const [visibleDialog, setVisibleDialog] = useState<VisibleDialog>(VisibleDialog.None);
  const mapRef = React.useRef(null);
  const mapDrawRef = React.useRef(null);
  const [mapDrawMode, setMapDrawMode] = useState('');
  const [mapDrawData, setMapDrawData] = useState(null);
  const [plot, setPlot] = useState<Plot>(null);
  const [searchOpen, setSearchOpen] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [showMapOverlay, setShowMapOverlay] = useState(false);
  const [mapBearing, setMapBearing] = useState(0);
  const [selectedHedgerow, setSelectedHedgerow] = useState<Hedgerow>(null);
  const [selectedHedgerowNewDrawing, setSelectedHedgerowNewDrawing] = useState<Hedgerow>(null);
  const [mapLibreStartOptions] = useState<StartupOptions>({
      latitude: 54.164,  //Centre on UK
      longitude: -4.640, //Centre on UK
      zoom: 5.0,
      apiKey: window.__RUNTIME_CONFIG__.REACT_APP_MAPTILER_API_KEY
    });

  //Occurs when the new plot dialog is successful, set the plot and close the dialog
  const handleNewPlotCreated = async (plot,plotPostcode) => {
    setVisibleDialog(VisibleDialog.None);
    setPlot(plot);
    setMapDrawData(null);
    setSearchValue(await fireSearch(plotPostcode));

    // set us in to draw mode
    handleHedgerowAreaEdit(plot.hedgeRows[0]);
  }

  //Will force a re-render of the component
  const forceUpdate = useReducer(() => ({}), {})[1] as () => void

  //Occurs when the map draw data (geometry feature) is updated
  //NOTE: drawData can be null which means no geometry feature is selected.
  const handleMapDrawDataUpdated = (drawData) => {

    //Debug logging
    if (window.__RUNTIME_CONFIG__.REACT_APP_DEBUG === "true")
      console.log("handleMapDrawDataUpdated()", JSON.stringify(drawData));

    //Store the latest draw data for the map overlay to display (if visible)
    setMapDrawData(drawData);

    //Get the current draw mode of map
    const currentMapDrawMode = mapDrawRef.current?.getMode();
    setMapDrawMode(currentMapDrawMode);

    //If null, then no polygon is selected.
    if (!drawData) {
      setSelectedHedgerow(null);
      return;
    }

    //New hedgerow area created
    if (drawData.action === 'draw.create') {
      selectedHedgerowNewDrawing.state.mapId = drawData.id;
      selectedHedgerowNewDrawing.coords = drawData.coords[0].map((coord) => {
        return { latitude: coord[1], longitude: coord[0] }; //Longitude is first element [0], Latitude is second [1]
      })

      selectedHedgerowNewDrawing.lastUpdated = DateTime.now();
      selectedHedgerowNewDrawing.areaMetreSquared = calculateAreaM2(selectedHedgerowNewDrawing.coords);

      /*
        HACK: Store the map draw data on the hedgerow to be used when the hedgerow is selected from the list.
        This is needed because event don't fire when the map is updated programmatically.
      */
      selectedHedgerowNewDrawing.state.mapDrawData = JSON.parse(JSON.stringify(drawData)); //Perform DEEP clone;
      selectedHedgerowNewDrawing.state.mapDrawData.action = 'draw.update';

      //update states so that UI reflects correctly
      setSelectedHedgerowNewDrawing(null);
      setSelectedHedgerow(selectedHedgerowNewDrawing);
    }

    //Existing hedgerow area deleted
    else if (drawData.action === 'draw.delete') {
      const index = plot.hedgeRows.findIndex((hedgerow) => hedgerow.state.mapId === drawData.id);
      plot.hedgeRows[index].coords = [];

      //update states so that UI reflects correctly
      setSelectedHedgerowNewDrawing(null);
      setSelectedHedgerow(null);
    }

    //Existing hedgerow area updated
    else if (drawData.action === 'draw.update') {
      const index = plot.hedgeRows.findIndex((hedgerow) => hedgerow.state.mapId === drawData.id);
      plot.hedgeRows[index].coords = drawData.coords[0].map((coord) => {
        return { latitude: coord[1], longitude: coord[0] }; //Longitude is first element [0], Latitude is second [1]
      })

      plot.hedgeRows[index].lastUpdated = DateTime.now();
      plot.hedgeRows[index].areaMetreSquared = calculateAreaM2(plot.hedgeRows[index].coords);

      /*
        HACK: Store the map draw data on the hedgerow to be used when the hedgerow is selected from the list.
        This is needed because event don't fire when the map is updated programmatically.
      */
      plot.hedgeRows[index].state.mapDrawData = JSON.parse(JSON.stringify(drawData)); //Perform DEEP clone;
      plot.hedgeRows[index].state.mapDrawData.action = 'draw.update';

      //Update states so that UI reflects correctly
      setSelectedHedgerowNewDrawing(null);
      setSelectedHedgerow(plot.hedgeRows[index]);
    }


    //Existing hedgerow area selected
    else if  (drawData.action === 'draw.select') {
      //Find the hedgerow that matches the mapId
      const index = plot.hedgeRows.findIndex((hedgerow) => hedgerow.state.mapId === drawData.id);
      plot.hedgeRows[index].coords = drawData.coords[0].map((coord) => {
        return { latitude: coord[1], longitude: coord[0] }; //Longitude is first element [0], Latitude is second [1]
      })

      //Update states so that UI reflects correctly
      setSelectedHedgerowNewDrawing(null);
      setSelectedHedgerow(plot.hedgeRows[index]);
    }

    //FAILSAFE - Clear selected hedgerow objects based on the draw mode
    if (currentMapDrawMode !== 'draw_polygon')
      setSelectedHedgerowNewDrawing(null);
    if (currentMapDrawMode === 'draw_polygon' && drawData.action !== 'draw.create')
      setSelectedHedgerow(null);
  }

  //Occurs when the map draw mode changes
  const handleMapDrawModeChange = (drawMode) => {
    setMapDrawMode(drawMode);

    //Debug logging
    if (window.__RUNTIME_CONFIG__.REACT_APP_DEBUG === "true")
      console.log("handleMapDrawModeChange", drawMode);
  }

  //Occurs when the key down on map
  const handleMapKeyDown = (event) => {
    //ESCAPE while on draw_polygon mode will cancel the drawing.  Clear the selected hedgerows so UI correct
    if ((event.key === "Escape") && (mapDrawRef.current?.getMode() === "draw_polygon")) {
      setSelectedHedgerowNewDrawing(null);
      setSelectedHedgerow(null);
    }
  }

  //Occurs when the map bearing changes
  const handleMapBearingChanged = (bearing) => {
    const simpleBearing = Math.floor(bearing);
    setMapBearing(simpleBearing);
  }


  /* KEEP FOR NOW!!!
  //Toggle the map to draw or view mode
  const toggleMapDrawMode = () => {

    const drawMode = mapDrawRef.current?.getMode();

    //'draw_polygon' is the mode to create a new polygon
    if (drawMode === 'draw_polygon') {
      mapDrawRef.current?.changeMode('simple_select');
      setDrawNewPolygon(false);
      setMapDrawMode('simple_select');
    } else {
      mapDrawRef.current?.changeMode('draw_polygon');
      setDrawNewPolygon(true);
      setMapDrawMode('draw_polygon');
    }
  }*/

  //Toggles the search input visible/hidden
  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  }

  const fireSearch = async(searchValue) => {

    if(!searchValue) return;

    //Force to uppercase and remove whitespace
    searchValue = searchValue.toUpperCase().replace(/\s/g, "");
    //Try retrieve the postcode
    try {
      const foundPostcode = await getPostcode(searchValue);
    
      //Close the search if postcode found as the map will zoom to it
      setSearchOpen(false);
  
      //Zoom to the postcode on the map
      mapRef.current.flyTo({center: [foundPostcode.longitude, foundPostcode.latitude], zoom: 15, duration: 2500, essential: true, pitch: 0, bearing: 0});
      return searchValue;
    }
    catch (error) {
      //Default error message
      let errorStruct = JSON.parse(error.message);
      let toastMessage = `[${errorStruct}] ${errorStruct.message}`;
            
      //Handle 404 error gracefully
      if (errorStruct.status === 404) toastMessage = `${searchValue} is not a valid UK postcode.`;
              
      //Show toast
      showToast({
        message: toastMessage,
        toastKind: ToastKind.Error,
        autoClose: ToastAutoClose.Always
      });
    }
  }

  //Occurs when key is pressed on the search
  const handleSearchKeyPress = async (event) => {
    //Ignore if not ENTER as we only want to search on ENTER
    if (event.key !== 'Enter') return;

    setSearchValue(await fireSearch(event.target.value));

  }

  //Occurs when the confirm delete area YES button is clicked
  const handleDeleteHedgerowAreaConfirmation = (accept) => {
    if (accept) mapDrawRef.current?.trash();
    setVisibleDialog(VisibleDialog.None);
  }

  const handleMaintainHedgerowUpdated = (incomingHedgerow: Hedgerow) => {

    //Debug logging
    if (window.__RUNTIME_CONFIG__.REACT_APP_DEBUG === "true")
      console.log("handleMaintainHedgerowUpdated()", JSON.stringify(incomingHedgerow));

    //Try get the array index of the hedgerow
    const index = plot.hedgeRows.findIndex((hedgerow) => hedgerow.id === incomingHedgerow.id);

    //Hedgerow doesn't exist, therefore add into array
    if (index === -1)
      plot.hedgeRows.push(incomingHedgerow);

    //Hedgerow exists, therefore update
    else
      plot.hedgeRows[index] = incomingHedgerow;

    //Close the dialog
    setVisibleDialog(VisibleDialog.None);
  }

  //Occurs when the cancel button is clicked on the create/edit hedgerow dialog
  const handleMaintainHedgerowCancelled = () => {
    setVisibleDialog(VisibleDialog.None);
  }

  //Occurs when the confirm delete hedgerow YES button is clicked
  const handleDeleteHedgerowConfirmation = async (accept: boolean) => {
    //Delete the hedgerow
    if (accept) {
      const index = plot.hedgeRows.findIndex((hedgerow) => hedgerow.id === selectedHedgerow.id);

      //Delete the hedgerow area if it exists
      mapDrawRef.current?.changeMode('simple_select', { featureIds: plot.hedgeRows[index].state.mapId })
      mapDrawRef.current?.trash()

      //Remove hedgerow from array
      plot.hedgeRows.splice(index, 1);

      //Clear selected
      setSelectedHedgerow(null);
    }

    //Close the dialog
    setVisibleDialog(VisibleDialog.None);
  }

  //Occurs when the DELETE button is clicked on a hedgerow
  const handleHedgerowDelete = async (hedgerow: Hedgerow) => {
    setSelectedHedgerow(hedgerow);
    setVisibleDialog(VisibleDialog.DeleteHedgerow);
  }

  //Occurs when the EDIT button is clicked on a hedgerow
  const handleHedgerowEdit = async (hedgerow: Hedgerow) => {
    setSelectedHedgerow(hedgerow);
    setVisibleDialog(VisibleDialog.EditHedgerow);
  }

  //Occurs when the EDIT AREA button is clicked on a hedgerow
  const handleHedgerowAreaEdit = async (hedgerow: Hedgerow) => {
    //Set the map to draw mode
    mapDrawRef.current?.changeMode('draw_polygon');
    setMapDrawMode('draw_polygon'); //HACK: Manually set as the events don't fire non user initiated changes
    mapRef.current.getCanvas().focus(); //Force focus on map so that ESC works without having to click on the map

    //Set the selected hedgerow for drawing
    setSelectedHedgerowNewDrawing(hedgerow);
    setSelectedHedgerow(null);  //Must be set to null as the hedgerow so UI reflects correctly
  }

  //Occurs when a hedgerow is selected from the list
  const handleHedgerowSelect = async (hedgerow: Hedgerow) => {
    //Select the hedgerow (feature) on the map
    if (!isPlotSubmitted(plot))
      mapDrawRef.current?.changeMode('simple_select', { featureIds: [hedgerow.state.mapId] })

    //Manually fire the handler as the events don't fire non user initiated changes
    handleMapDrawDataUpdated(hedgerow.state.mapDrawData);
  }

  //Occurs when a ADD HEDGEROW button is clicked
  const handleAddHedgerowClick = async () => {
    const hedgerow = createNewHedgerow(plot)
    setSelectedHedgerow(hedgerow);
    handleMaintainHedgerowUpdated(hedgerow);
  }

  //Occurs when the SUBMIT PLOT button is clicked
  const handleSubmitPlot = async () => {

    //Update all hedgerow states to show invalid indicators (if invalid)
    plot.hedgeRows.forEach((hedgerow) => {
      hedgerow.state.showInvalidIndicator = true;
    });

    //Purposely for a refresh of the components to show the invalid indicators
    forceUpdate();

    //Plot not ready for submission
    if (!isPlotValid(plot)) return;

    //Open submission dialog
    setVisibleDialog(VisibleDialog.SubmitPlot)
  }

  //Occurs when the submit plot dialog is successful, set the plot and close the dialog
  const handlePlotSubmitted = async (submittedPlot: Plot) => {
    setVisibleDialog(VisibleDialog.None);
    setSelectedHedgerow(null);
    setSelectedHedgerowNewDrawing(null);

    //Force map to static mode
    mapDrawRef.current?.changeMode('static');
    setMapDrawMode('static');  //HACK: Manually set as the events don't fire non user initiated changes

    //Create the HTML message (JSX Element) to be display
    const messageComponent = () => (
      <>
        Plot <b>{submittedPlot.name}</b> submitted successfully, editing has been disabled.
        <br/><br/>
        Your carbon report will be sent to <b>{submittedPlot.submissionInfo.ownerEmailAddress}</b> once finalised
      </>
    );

    //Show the toast
    showToast({
      message: messageComponent,
      toastKind: ToastKind.Success,
      autoClose: ToastAutoClose.Never
    });

    //Force a full refresh of the page
    forceUpdate();
  }

  return (
    <div className={styles.pageContainer}>

      <div className={styles.sideContainer}>

        {/* The LOGO left hand side section */}
        <div className={styles.logoContainer}>
          <a href="/">
            <img alt="Carbon Keepers Logo" src={logo} />
          </a>
        </div>

        {/* The PLOT left hand side section */}
        {!plot && (
          <div className={styles.plotContainer}>
            <Button
                fullWidth={true}
                className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularPrimary}`}
                aria-label="Add Your Land"
                title="Add Your Land"
                onClick={() => { setVisibleDialog(VisibleDialog.CreatePlot)}}>
                Add Your Land
              </Button>
          </div>
        )}


        {/* The PLOT left hand side section */}
        {plot && (
          <>
            {/* The PLOT left hand side section */}
            <div className={styles.plotContainer}>
              <Map className={styles.plotIcon} />
              <label className={styles.plotLabel}>{plot.name}</label>
            </div>

            <div className={styles.hedgerowsContainer}>
              {plot.hedgeRows.length > 0 && (
                <HedgerowList
                  canEdit={!isPlotSubmitted(plot)}
                  canSelect={!isPlotSubmitted(plot)}
                  hedgerows={plot.hedgeRows}
                  selectedHedgerow={selectedHedgerow}
                  onHedgerowDelete={handleHedgerowDelete}
                  onHedgerowEdit={handleHedgerowEdit}
                  onHedgerowAreaEdit={handleHedgerowAreaEdit}
                  onHedgerowSelect={handleHedgerowSelect}
                />
              )}

              {!isPlotSubmitted(plot) && (
                <>
                  <Button
                    fullWidth={true}
                    className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularGrey}`}
                    aria-label="Add hedgerow"
                    title="Add hedgerow"
                    onClick={handleAddHedgerowClick}>
                    <AddCircleOutline className={globalStyles.buttonIcon} fontSize="small" />
                    Add Hedgerow
                  </Button>
                </>
              )}

              {!isPlotSubmitted(plot) && (
                <>
                  <Divider />
                  <Button
                    fullWidth={true}
                    className={isPlotValid(plot) ? `${globalStyles.buttonRegular} ${globalStyles.buttonRegularPrimary}` : `${globalStyles.buttonRegular} ${globalStyles.buttonRegularGrey}`}
                    aria-label="Calculate Carbon Stock"
                    title="Calculate Carbon Stock"
                    onClick={handleSubmitPlot}>
                    <CloudUpload className={globalStyles.buttonIcon} fontSize="small" />
                    Calculate Carbon Stock
                  </Button>
                </>
              )}

            </div>
          </>
        )}
      </div>

      {/* The MapBox  and map related controls  */}
      <div className={styles.mapContainer}>

        {/* The MapBox canvas */}
        <MapLibre
          startupOptions={mapLibreStartOptions}
          keyboardEnabled={false}
          onMapCreated={(map) => { mapRef.current = map; } }
          onDrawControlCreated={(drawControls) => { mapDrawRef.current = drawControls; } }
          onBearingChanged={handleMapBearingChanged}
          onDrawDataUpdated={handleMapDrawDataUpdated}
          onDrawModeChange={handleMapDrawModeChange}
          onKeyDown={handleMapKeyDown}
        />

        {/* The search input */}
        {searchOpen && (
          <input
            autoFocus
            type="text"
            spellCheck="false"
            autoComplete="off"
            placeholder="Enter postcode"
            className={styles.searchInput}
            onKeyUp={handleSearchKeyPress}
            value={searchValue}
            onChange={(event) => { setSearchValue(event.target.value); }}
          />
        )}

        {/* The right side buttons */}
        <div className={styles.rightMapButtonsContainer}>
          <div className={globalStyles.verticalStack}>
            <IconButton aria-label="search"
              className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularGrey} ${globalStyles.buttonIconMap}`}
              title="Search by postal code"
              onClick={toggleSearch}>
              <Search />
            </IconButton>
            <IconButton aria-label="zoom-in"
              className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularGrey} ${globalStyles.buttonIconMap}`}
              title="Zoom-in the map"
              onClick={() => { mapRef.current?.zoomIn({duration: 600}); }}>
              <Add />
            </IconButton>
            <IconButton aria-label="zoom-out"
              className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularGrey} ${globalStyles.buttonIconMap}`}
              title="Zoom-out the map"
              onClick={() => { mapRef.current?.zoomOut({duration: 600}); }}>
              <Remove />
            </IconButton>
            <IconButton aria-label="bearing"
              className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularGrey} ${globalStyles.buttonIconMap}`}
              title="Set map bearing north"
              onClick={() => { mapRef.current?.resetNorthPitch({duration: 1200}); }}>
              <Navigation sx={{ transform: `rotate(${mapBearing}deg)` }}  />
            </IconButton>

            {/* Map overload only for debugging */}
            {window.__RUNTIME_CONFIG__.REACT_APP_DEBUG === "true" && (
              <>
                <IconButton aria-label="DEBUG: Show/Hide geometry overlay"
                  className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularRed} ${globalStyles.buttonIconMap}`}
                  title="DEBUG: Show/Hide geometry overlay"
                  onClick={() => { setShowMapOverlay(!showMapOverlay);}}
                  aria-pressed={showMapOverlay}>
                  <Layers/>
                </IconButton>

                <IconButton aria-label="DEBUG: Dump plot to console"
                  className={`${globalStyles.buttonRegular} ${globalStyles.buttonRegularRed} ${globalStyles.buttonIconMap}`}
                  title="DEBUG: Dump plot to console"
                  onClick={() => { if (plot) console.log("Plot", JSON.stringify(plot));}}>
                  <TextSnippetOutlined/>
                </IconButton>
              </>
            )}
          </div>
        </div>

        {/* The left side drawing buttons */}
        {plot && (
          <div className={styles.leftMapButtonsContainer}>
            <div className={globalStyles.verticalStack}>
              {mapDrawMode === "draw_polygon" && selectedHedgerowNewDrawing && (
                <div className={`${styles.mapDrawModeButton}`}>
                  <Polyline />
                  <div>
                    Drawing hedgerow for <b>{selectedHedgerowNewDrawing.name}</b>.<br/>
                    <b>Double-Click</b> to finish, <b>ESC</b> to cancel.
                  </div>
                </div>
              )}

               {/* Only show delete button when a polygon is selected */}
              {mapDrawData && mapDrawData.action !== "draw.delete" && mapDrawMode !== "direct_select" && mapDrawMode !== "static" && (
                <IconButton aria-label="delete map features"
                  className={`${globalStyles.buttonRegular}  ${globalStyles.buttonRegularGrey} ${globalStyles.buttonIconMap}`}
                  title="Delete map features"
                  onClick={() => { setVisibleDialog(VisibleDialog.DeleteHedgerowArea); }}>
                  <Delete />
                </IconButton>
              )}
            </div>
          </div>
        )}

        {/* DEBUG: Map geometry info */}
        {showMapOverlay && (
          <MapOverlay
            plot={plot}
            drawData={mapDrawData}
            drawMode={mapDrawMode}
            selectedHedgerow={selectedHedgerow}
            selectedHedgerowNewDrawing={selectedHedgerowNewDrawing}
            />
        )}
      </div >

      <CreatePlotDialog
        open={visibleDialog === VisibleDialog.CreatePlot}
        onCancelled={() => { setVisibleDialog(VisibleDialog.None);}}
        onCreated={handleNewPlotCreated}
      />

      {plot && (
      <MaintainHedgerowDialog
        open={visibleDialog === VisibleDialog.CreateHedgerow || visibleDialog === VisibleDialog.EditHedgerow}
        canEdit={!isPlotSubmitted(plot)}
        onCancelled={handleMaintainHedgerowCancelled}
        onUpdated={handleMaintainHedgerowUpdated}
        hedgerow={selectedHedgerow}
      />
      )}

      <ConfirmationDialog
        open={visibleDialog === VisibleDialog.DeleteHedgerow}
        title={'Delete Hedgerow'}
        description={`Are you sure you want to delete <b>${selectedHedgerow?.name}</b>?`}
        onButtonClick={handleDeleteHedgerowConfirmation}
        acceptText={'Yes'}
        rejectText={'No'}
      />

      <ConfirmationDialog
        open={visibleDialog === VisibleDialog.DeleteHedgerowArea}
        title={'Delete Hedgerow Area'}
        description={`Are you sure you want to delete drawn area for <b>${selectedHedgerow?.name}</b>?`}
        onButtonClick={handleDeleteHedgerowAreaConfirmation}
        acceptText={'Yes'}
        rejectText={'No'}
      />

      {plot && !plot.submissionInfo && (
        <SubmitPlotDialog
          open={visibleDialog === VisibleDialog.SubmitPlot}
          plot={plot}
          onCancelled={() => setVisibleDialog(VisibleDialog.None)}
          onSubmitted={(plot) => handlePlotSubmitted(plot)}
        />
      )}

    </div>
  );
}
